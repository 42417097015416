$(function() {

    $('.realization-description').hide();

    $('.realization-description .close-realization').click(function (e) {
        e.preventDefault();
        $('.realizations-list li').removeClass('active');
        $(this).parents('.realization-description').slideUp(500);
    });

    $('.realizations-list li').click(function (e) {
        e.preventDefault();

        if($(this).hasClass('active')) {
            $(this).removeClass('active');
            $('.realization-description').slideUp(500);
            return;
        }

        $('.realizations-list li').removeClass('active');
        $(this).addClass('active');

        let description = $('.realization-description[data-id="'+$(this).data('id')+'"]');
        if(!description.length) return;

        $('.realization-description').slideUp(500);
        $(description[0]).slideDown(500, () => {
            $([document.documentElement, document.body]).animate({
                scrollTop: $(description[0]).offset().top - 30 - window.getNavHeight()
            }, 500);
        });

    });

    $('.realizations-wrap .service-item').click(function (e) {
        e.preventDefault();
        $(this).parent().toggleClass('active');
        $(this).toggleClass('active');
        $(this).parent().find('.bottom-content').slideToggle(500);
    });

});