import Swiper from 'swiper';

window.getNavHeight = function() {
   return $('.main-nav').height();
};


let resize_interval = null;
let cachedWidth = $(window).width();

let fixVideoSectionHeight = function() {
   let navHeight = $('.main-nav').height();
   $('.video-section').height(window.innerHeight - navHeight + 15);
};

$(function () {

   fixVideoSectionHeight();

   $(window).resize(function (e) {
      let newWidth = $(window).width();

      if (newWidth !== cachedWidth) {
         clearTimeout(resize_interval);
         resize_interval = setTimeout(fixVideoSectionHeight, 500);
         cachedWidth = newWidth;
      }
   });

   $('.lang-switch').click(() => {

      let langSwitch = $('.lang-switch');

      if($(langSwitch).hasClass('left')) {
         $(langSwitch).removeClass('left');
         $(langSwitch).addClass('right');
      }
      else {
         $(langSwitch).removeClass('right');
         $(langSwitch).addClass('left');
      }
   });

   setTimeout(() => {

      let prizesSwiper = new Swiper ('#prizes-slider', {
         slidesPerView: 1,
         loop: true,
         autoHeight: true,
         navigation: {
            nextEl: $('.prizes-section .slider-button-next')[0],
            prevEl: $('.prizes-section .slider-button-prev')[0],
         },
      });

   }, 300);

   $(document.body).addClass('ready');

   setTimeout(() => {
      $('#loader').remove();
   }, 200);

   let menuItems = $('.main-nav .menu-item');

   for(let i = 0; i < menuItems.length; i++) {
      let link = $(menuItems[i]).find('a');
      if(!link.length) continue;

      $(link).attr('data-title', $(link).text());
   }

});