$(function () {

    $(document).on({
        mouseenter: function() {
            $('.main-nav .links').addClass('hover');
        },
        mouseleave: function() {
            $('.main-nav .links').removeClass('hover');
        }
    }, '.menu-item');

    let smallNav = false;

    let checkNav = function(){

        if($(window).scrollTop() > 50 && smallNav === false){
            $('.main-nav').addClass('small');
            smallNav = true;
        } else if ($(window).scrollTop() <= 50) {
            $('.main-nav').removeClass('small');
            smallNav = false;
        }

    };

    $(window).resize(() => {
        $('.main-nav').find('.links').attr('style', null);
    });

    $(window).scroll(function() {
        checkNav();
        $('.main-nav').find('.links').attr('style', null);
    });

    checkNav();

    $('.nav-trigger').click(() => {
        $('.main-nav').find('.links').slideToggle();
    });

});