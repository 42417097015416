$(function() {

    let serviceListBusy = false;

    let insertServiceDescription = function(breakPoint, descriptionBox, group, serviceItem) {

        if(window.innerWidth > breakPoint) {
            $(descriptionBox).insertAfter($(group));
        }
        else {
            $(descriptionBox).insertAfter($(serviceItem));
        }

        $('.service-description').slideDown(500, () => {
            serviceListBusy = false;
        });
    };

    let handleServiceItemClick = function(text, serviceItem) {

        if(serviceListBusy) return;

        serviceListBusy = true;

        let list = $(serviceItem).parents('.service-list');
        let breakPoint = $(list).data('breakpoint');
        let group = $(serviceItem).parents('.service-group');

        let oldDescriptionBoxes = $('.service-description');
        let descriptionBox = $('<div class="service-description"><div class="container-padding">' + text + '<span class="close-trigger"></span></div></div>');

        if($(serviceItem).hasClass('active')) {
            $(oldDescriptionBoxes).slideUp(500, () => {
                $(oldDescriptionBoxes).remove();
                $(serviceItem).removeClass('active');
                serviceListBusy = false;
            });
            return;
        }
        else {
            $('.service-item').removeClass('active');
            $(serviceItem).addClass('active');
        }

        if($(oldDescriptionBoxes).length) {
            $(oldDescriptionBoxes).slideUp(500, () => {
                $(oldDescriptionBoxes).remove();
                insertServiceDescription(breakPoint, descriptionBox, group, serviceItem);
            });
        }
        else {
            insertServiceDescription(breakPoint, descriptionBox, group, serviceItem);
        }
    };

    let initialize = function() {
        let hash = window.location.hash;
        if(!hash.length) return;

        let serviceItem = $('.service-item' + hash);
        if(!$(serviceItem).length) return;

        $([document.documentElement, document.body]).animate({
            scrollTop: $(serviceItem[0]).offset().top - window.getNavHeight()
        }, 500, () => {


            handleServiceItemClick(
                $(serviceItem[0]).find('.service-description-text').html(),
                $(serviceItem[0])
            );

        });


    };

    initialize();

    $('.offer-wrap .service-description-text').hide();

    $('.offer-wrap .service-item').click(function (e) {

        e.preventDefault();

        handleServiceItemClick(
            $(this).find('.service-description-text').html(),
            this
        );
    });

    $('.offer-wrap .service-list.actions').on('click', '.close-trigger', function (e){

        serviceListBusy = true;

        $('.service-description').slideUp(500, () => {
            $('.service-description').remove();
            $('.service-item').removeClass('active');
            serviceListBusy = false;
        });
    });

    $(window).resize(() => {
        $('.offer-wrap .service-list.actions .service-item').removeClass('active');
        $('.offer-wrap .service-list.actions .service-description').remove();
    });

});