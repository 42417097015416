import Swiper from "swiper";

let sliderDOM = $('#cooperation-slider');
let cooperationDetails = $('.cooperation-details');

$(function () {

    let cooperationSwiper = new Swiper('#cooperation-slider', {
        slidesPerView: 5,
        loop: true,
        spaceBetween: 20,
        autoplay: {
            delay: 3000
        },
        navigation: {
            nextEl: $('.cooperation-section .slider-button-next')[0],
            prevEl: $('.cooperation-section .slider-button-prev')[0],
        },
        breakpoints: {
            0: {
                slidesPerView: 2
            },
            400: {
                slidesPerView: 3
            },
            500: {
                slidesPerView: 4
            },
            666: {
                slidesPerView: 5
            }
        }
    });

    sliderDOM.on('click', '.swiper-slide', function (e) {

        let id = $(this).data('id');

        if (!id) return;

        let descriptionItem = $('.details-wrap[data-id="' + id + '"]');

        if(!descriptionItem.length) return;

        $('.details-wrap').hide();
        $(descriptionItem).show();

        if ($(cooperationDetails).is(":hidden")) {

            $([document.documentElement, document.body]).animate({
                scrollTop: $(".container-slider").offset().top - window.getNavHeight()
            }, 500);

            $(cooperationDetails).addClass('sliding');
            $(cooperationDetails).slideDown(400, () => {
                $(cooperationDetails).removeClass('sliding');
            });
        }

    });

    $('.cooperation-details .close-button').click(() => {
        $(cooperationDetails).addClass('sliding');
        $(cooperationDetails).slideUp(400, () => {
            $(cooperationDetails).removeClass('sliding');
        });
    });

});