$(function () {

    if(!$('.reference-items').length) return;

    let slidingInProgress = false;

    $(document.body).on('click', '.description .close-button', function() {
        $('.reference-items .description').slideUp();
        $('.reference-item.active').removeClass('active');
        slidingInProgress = false;
    });

    $('.show-references-btn').click(function(e) {

        e.preventDefault();

        let referenceItem = $(this).parents('.reference-item');

        if(slidingInProgress) return;
        if($(referenceItem).hasClass('active')) return;

        slidingInProgress = true;

        $('.reference-items .description').slideUp();
        $('.reference-item.active').removeClass('active');


        let currentIndex = $(referenceItem).data('index');

        let windowWidth = $(window).width();

        let divider = 4;

        if(windowWidth > 780) {
            divider = 4;
        }
        else if(windowWidth > 470) {
            divider = 2;
        }
        else {
            divider = 1;
        }

        let insertAfterIndex = divider;

        if(currentIndex % divider === 0)
        {
            insertAfterIndex = currentIndex;
        }
        else
        {
            insertAfterIndex = (currentIndex + divider) - (currentIndex %divider);
        }

        if(insertAfterIndex > $('.reference-item').length) {
            insertAfterIndex = $('.reference-item').length;
        }

        let insertAfter = $('.reference-item[data-index="' + insertAfterIndex + '"]');
        let insertContainer = $('<div class="description"></div>');
        $(insertContainer).html($(referenceItem).find('.reference-files').html());

        $(insertContainer).insertAfter(insertAfter).slideDown(300, () => {
            $(referenceItem).addClass('active');
            slidingInProgress = false;
        });

    });

    $(window).resize(() => {
        $('.reference-items .description').slideUp();
        $('.reference-item.active').removeClass('active');
        slidingInProgress = false;
    });

});